import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { set as setNotification } from 'store/notification/actions'

import { fetchOne, add, update, remove } from 'UserApp/store/pets/actions'
import PetForm from 'UserApp/components/Pets/Form'
import { FORMS } from '../constants'

class PetFormContainer extends React.PureComponent {
  static propTypes = {
    add: PropTypes.func,
    setNotification: PropTypes.func,
    currentForm: PropTypes.string,
    fetchOne: PropTypes.func,
    id: PropTypes.string,
    onChangedView: PropTypes.func,
    onGoBack: PropTypes.func,
    onClose: PropTypes.func,
    remove: PropTypes.func,
    update: PropTypes.func,
  }

  state = {
    currentForm: FORMS.GENERAL,
    isLoading: false,
    isProcessing: false,
    values: { meta: { insurance: {} } },
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && props.id !== state.id) {
      return {
        id: props.id,
      }
    }

    return null
  }

  componentDidMount() {
    if (this.state.id) {
      this.fetch()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.onChangedView &&
      this.state.currentForm !== prevState.currentForm
    ) {
      this.props.onChangedView()
    }

    if (this.state.id && this.state.id !== prevState.id) {
      this.fetch()
    }
  }

  fetch = async () => {
    this.setState({
      isLoading: true,
    })

    const { user, fetchOne } = this.props
    const { id } = this.state

    const values = await fetchOne(id)

    this.setState({
      values: {
        ...values,
        email: values.email || user.email,
        phone: values.phone || user.phone,
        meta: {
          ...values.meta,
          insurance: values.meta.insurance || {},
        },
      },
      isLoading: false,
    })
  }

  getCollectionKey = () => {
    const { currentForm } = this.state

    if (currentForm === FORMS.CARE_PROVIDER) {
      return `careProviders`
    } else if (currentForm === FORMS.EDIT_PET_PHOTO) {
      return `photo`
    } else if (currentForm === FORMS.TECH_DEVICE) {
      return `techDevices`
    } else if (currentForm === FORMS.DOCUMENT) {
      return `documents`
    } else if (currentForm === FORMS.CHIP_ID) {
      return `chipIDs`
    } else if (currentForm === FORMS.VET_CLINIC) {
      return `vetClinics`
    } else if (currentForm === FORMS.INSURANCE) {
      return `insurances`
    }
  }

  getIsMetaCollection = () => {
    const { currentForm } = this.state

    return currentForm !== FORMS.DOCUMENT
  }

  getCollectionValues = () => {
    const { currentForm, selectedCollectionIndex, values } = this.state

    if (currentForm === FORMS.EDIT_PET_PHOTO) {
      return values.tempPhoto
    }

    if (currentForm && typeof selectedCollectionIndex !== 'undefined') {
      const collectionKey = this.getCollectionKey()
      if (collectionKey) {
        const isMetaCollection = this.getIsMetaCollection()
        return isMetaCollection
          ? values.meta[collectionKey][selectedCollectionIndex]
          : values[collectionKey][selectedCollectionIndex]
      }
    }
  }

  handleCollectionRemove = () => {
    const { setNotification } = this.props
    const { currentForm, selectedCollectionIndex } = this.state
    const collectionKey = this.getCollectionKey()
    const isMetaCollection = this.getIsMetaCollection()

    if (currentForm === FORMS.EDIT_PET_PHOTO) {
      this.setState((state) => {
        return {
          values: {
            ...state.values,
            photo: null,
          },

          currentForm: FORMS.GENERAL,
        }
      })
    } else {
      this.setState(
        (state) => {
          const values = isMetaCollection
            ? {
                ...state.values,
                meta: {
                  ...state.values.meta,
                  [collectionKey]: state.values.meta[collectionKey].filter(
                    (item, index) => index !== selectedCollectionIndex,
                  ),
                },
              }
            : {
                ...state.values,
                [collectionKey]: state.values[collectionKey].filter(
                  (item, index) => index !== selectedCollectionIndex,
                ),
              }

          let newCurrentForm = FORMS.DOCUMENTS_AND_EXTRAS

          switch (state.currentForm) {
            case FORMS.DOCUMENT:
              setNotification(`Document removed`)
              break
            case FORMS.TECH_DEVICE:
              setNotification(`Tech device removed`)
              break
            case FORMS.CHIP_ID:
              setNotification(`Microchip ID removed`)
              break
            case FORMS.VET_CLINIC:
              newCurrentForm = FORMS.DIET_AND_MEDICAL
              setNotification(`Vet clinic removed`)
              break
            case FORMS.INSURANCE:
              setNotification(`Insurance removed`)
              break
            case FORMS.CARE_PROVIDER:
              setNotification(`Care provider removed`)
              break
          }

          return {
            selectedCollectionIndex: undefined,
            currentForm: newCurrentForm,
            values,
          }
        },
        () => {
          this.saveIfWeCan()
        },
      )
    }
  }

  handleCollectionFormSubmit = async (data) => {
    const { currentForm } = this.state
    const { setNotification } = this.props

    if (currentForm === FORMS.EDIT_PET_PHOTO) {
      this.setState((state) => {
        return {
          values: {
            ...state.values,
            tempPhoto: undefined,
            photo: {
              ...data,
            },
          },
          currentForm: FORMS.GENERAL,
        }
      })
    } else {
      const { selectedCollectionIndex } = this.state
      const collectionKey = this.getCollectionKey()
      const isMetaCollection = this.getIsMetaCollection()

      this.setState(
        (state) => {
          let collection
          const isUpdating = typeof selectedCollectionIndex !== 'undefined'

          if (isMetaCollection) {
            collection = state.values.meta[collectionKey] || []
          } else {
            collection = state.values[collectionKey] || []
          }

          if (isUpdating) {
            collection = collection.map((item, index) => {
              if (index === selectedCollectionIndex) {
                return data
              } else {
                return item
              }
            })
          } else {
            collection = [...collection, data]
          }

          const values = isMetaCollection
            ? {
                ...state.values,
                meta: {
                  ...state.values.meta,
                  [collectionKey]: collection,
                },
              }
            : {
                ...state.values,
                [collectionKey]: collection,
              }
          let newCurrentForm = FORMS.DOCUMENTS_AND_EXTRAS

          switch (state.currentForm) {
            case FORMS.DOCUMENT:
              setNotification(`Document ${isUpdating ? `updated` : `added`}`)
              break
            case FORMS.TECH_DEVICE:
              setNotification(`Tech device ${isUpdating ? `updated` : `added`}`)
              break
            case FORMS.CHIP_ID:
              setNotification(
                `Microchip ID ${isUpdating ? `updated` : `added`}`,
              )
              break
            case FORMS.VET_CLINIC:
              newCurrentForm = FORMS.DIET_AND_MEDICAL
              setNotification(`Vet clinic ${isUpdating ? `updated` : `added`}`)
              break
            case FORMS.INSURANCE:
              setNotification(`Insurance ${isUpdating ? `updated` : `added`}`)
              break
            case FORMS.CARE_PROVIDER:
              setNotification(
                `Care provider ${isUpdating ? `updated` : `added`}`,
              )
              break
          }
          return {
            selectedCollectionIndex: undefined,
            currentForm: newCurrentForm,
            values,
          }
        },
        () => {
          this.saveIfWeCan()
        },
      )
    }
  }

  saveIfWeCan = async () => {
    const { update } = this.props
    const { values, id } = this.state

    if (id) {
      this.setState({
        isProcessing: true,
      })

      const data = {
        ...values,
        photoId: values.photo && values.photo.id,
        documentIds: values.documents && values.documents.map(({ id }) => id),
      }

      try {
        await update(id, data)
        this.setState({
          isProcessing: false,
        })
      } catch (error) {
        console.log(error)
        this.setState({
          isProcessing: false,
          error,
        })
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { currentForm, values, id } = this.state
    const { update, add, onClose } = this.props

    if (currentForm === FORMS.GENERAL) {
      const { name, photo } = values

      if (!name || name === '') {
        this.setState({ error: `Name required` })
        return
      }

      if (!photo) {
        this.setState({ error: `Upload your pet's photo to save information` })
        return
      }

      if (!id) {
        this.setState({
          isProcessing: true,
        })

        const data = {
          ...values,
          photoId: values.photo && values.photo.id,
          documentIds: values.documents && values.documents.map(({ id }) => id),
        }

        try{
          const result = await add(data)

          this.setState({
            isProcessing: false,
            id: result.id,
            error: '',
            currentForm: FORMS.DIET_AND_MEDICAL,
          })

          onClose(result);
        } catch (error){
          this.setState({
            isProcessing: false,
            error,
          });
        }
        return
      }
    } else if (currentForm === FORMS.DIET_AND_MEDICAL && !id) {
      this.setState({ currentForm: FORMS.DOCUMENTS_AND_EXTRAS })
      return
    }

    try {
      this.setState({
        isProcessing: true,
      })

      const data = {
        ...values,
        photoId: values.photo && values.photo.id,
        documentIds: values.documents && values.documents.map(({ id }) => id),
      }

      let result

      if (id) {
        result = await update(id, data)
      } else {
        result = await add(data)
      }

      onClose(result)
    } catch (error) {
      console.log(error)
      this.setState({
        isProcessing: false,
        error,
      })
    }
  }

  handleGoBack = () => {
    const { currentForm } = this.state
    if (
      currentForm === FORMS.INTELLITAG ||
      currentForm === FORMS.DIET_AND_MEDICAL ||
      currentForm === FORMS.EDIT_PET_PHOTO
    ) {
      this.setState({ currentForm: FORMS.GENERAL })
    } else if (
      currentForm === FORMS.DOCUMENTS_AND_EXTRAS ||
      currentForm === FORMS.VET_CLINIC
    ) {
      this.setState({ currentForm: FORMS.DIET_AND_MEDICAL })
    } else if (
      currentForm === FORMS.DOCUMENT ||
      currentForm === FORMS.CARE_PROVIDER ||
      currentForm === FORMS.TECH_DEVICE ||
      currentForm === FORMS.CHIP_ID ||
      currentForm === FORMS.INSURANCE
    ) {
      this.setState({
        selectedCollectionIndex: undefined,
        currentForm: FORMS.DOCUMENTS_AND_EXTRAS,
      })
    }
  }

  handleChange = ({ name, value }) => {
    this.setState((state) => {
      const path = name.split(`.`)

      if (path[0] === 'meta') {
        if (path[1] === 'insurance') {
          return {
            values: {
              ...state.values,
              meta: {
                ...state.values.meta,
                insurance: {
                  ...state.values.meta.insurance,
                  [path[2]]: value,
                },
              },
            },
          }
        } else {
          return {
            values: {
              ...state.values,
              meta: {
                ...state.values.meta,
                [path[1]]: value,
              },
            },
          }
        }
      } else {
        if (name === 'tempPhoto' && value) {
          return {
            currentForm: FORMS.EDIT_PET_PHOTO,
            values: {
              ...state.values,
              [path[0]]: value,
            },
          }
        } else {
          return {
            values: {
              ...state.values,
              [path[0]]: value,
            },
          }
        }
      }
    })
  }

  handleChangeForm = (currentForm, selectedCollectionIndex) => {
    this.setState({ currentForm, selectedCollectionIndex })
  }

  handleRemove = () => this.setState({ isConfirmingRemove: true })

  handleCancelRemove = () => this.setState({ isConfirmingRemove: false })

  handleConfirmRemove = async () => {
    const { remove, onClose, id } = this.props

    this.setState({
      isProcessing: true,
    })

    try {
      await remove(id)
      onClose()
    } catch (error) {
      this.setState({
        isProcessing: false,
      })
      throw error
    }
  }

  generateProps = () => {
    const { currentForm } = this.state
    const { id, onGoBack } = this.props

    return {
      ...this.props,
      ...this.state,
      onGoBack: currentForm !== FORMS.GENERAL ? this.handleGoBack : onGoBack,
      onSubmit: this.handleSubmit,
      onCollectionRemove: this.handleCollectionRemove,
      onCollectionFormSubmit: this.handleCollectionFormSubmit,
      onChangeForm: this.handleChangeForm,
      onChange: this.handleChange,
      onRemove: this.handleRemove,
      onConfirmRemove: this.handleConfirmRemove,
      onCancelRemove: this.handleCancelRemove,
      collectionValues: this.getCollectionValues(),
      isEditing: !!id,
    }
  }

  render() {
    const props = this.generateProps()

    return <PetForm {...props} />
  }
}

export default connect(
  (state) => ({
    hasSubscription: state.user.hasSubscription,
    user: state.user,
  }),
  {
    add,
    update,
    remove,
    fetchOne,
    setNotification,
  },
)(PetFormContainer)
