import React from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import { TIME } from 'constants/dateFormats'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { Button } from 'components/Forms'
import { AlarmType } from 'types'
import TurnOffConfirmation from '../TurnOffConfirmation'
import SOSButton from '../SOSButton'

import style from './style.module.scss'

ExpiredAlarmBanner.propTypes = {
  onDismiss: PropTypes.func,
}

function ExpiredAlarmBanner(props) {
  const { onDismiss } = props

  return (
    <div className={classNames(style.wrapper, style.expired)}>
      <HeaderOne className={style.header}>Your alarm went off</HeaderOne>
      <Button
        onClick={onDismiss}
        variant='red'
        className={style.dismissButton}
        small
      >
        Dismiss
      </Button>
    </div>
  )
}

function SetAlarmBanner(props) {
  const { alarm, onTurnOff, onResetUntilTomorrow } = props
  // console.log(`ALARM DATA :: `,alarm)
  const { mode, startTime, isToday, isFiring, isSOS } = alarm

  // function getFormattedTime(alarmTime, timeFormat) {
  //   const formattedTime = format(alarmTime, timeFormat);
    
  //   // Handle edge cases for 12:00 PM and 12:00 AM
  //   const hours = alarmTime.getHours();
  //   if ((hours === 12 || hours === 0) && formattedTime.includes('AM')) {
  //     return formattedTime.replace('AM', 'PM');
  //   }
  //   if ((hours === 12 || hours === 0) && formattedTime.includes('PM')) {
  //     return formattedTime.replace('PM', 'AM');
  //   }
  //   return formattedTime;
  // }

  return (
    <div
      className={classNames(style.wrapper, {
        [style.firing]: isFiring,
      })}
    >
      {!isFiring && <SOSButton className={style.sosButton} />}
      <HeaderOne className={style.header}>
      {isFiring
          ? ((isSOS) ? `Your SOS alarm is activated` : ((mode==='manual') ? `Your Daily Check-in alarm is going off` : `Your Location-based alarm is going off`))
          : `${(mode==='manual')?'Daily Check-in':'Location-based'} alarm will go off at ${format(startTime, TIME)} ${
              isToday ? 'today' : 'tomorrow'
            }`}
      </HeaderOne>

      {isToday && mode !== 'automatic' && isSOS!==true && (
        <Button
          onClick={onResetUntilTomorrow}
          variant='hollow-white'
          className={style.button}
          small
        >
          Reset Check-In Time
        </Button>
      )}

      <Button
        onClick={onTurnOff}
        variant='red'
        className={classNames(style.offButton, style.button)}
        small
      >
        { (isSOS) ? 'Stop SOS' : 'Turn Alarm Off'}
      </Button>
    </div>
  )
}

SetAlarmBanner.propTypes = AlarmBanner.propTypes = {
  alarm: PropTypes.shape(AlarmType),
  isLoading: PropTypes.bool,
  isTurningOff: PropTypes.bool,
  onCancelTurnOff: PropTypes.func,
  onConfirmTurnOff: PropTypes.func,
  onTurnOff: PropTypes.func,
  onDismiss: PropTypes.func,
  onResetUntilTomorrow: PropTypes.func,
}

function AlarmBanner(props) {
  const {
    alarm,
    isLoading,
    onCancelTurnOff,
    onConfirmTurnOff,
    onDismiss,
    isTurningOff,
  } = props
  const { isExpired, isComplete, isSOS } = alarm

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      {isExpired || isComplete ? (
        <ExpiredAlarmBanner onDismiss={onDismiss} />
      ) : (
        <SetAlarmBanner {...props} />
      )}

      {isTurningOff && (
        <Modal onClose={onCancelTurnOff}>
          {() => (
            <TurnOffConfirmation
              onConfirm={onConfirmTurnOff}
              onCancel={onCancelTurnOff}
              isSOS={isSOS}
            />
          )}
        </Modal>
      )}
    </React.Fragment>
  )
}

export default AlarmBanner
