import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import numeral from 'numeral'
import {
  useQuery,
} from 'react-query'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  Label,
  FieldGroup,
  Text,
  Header,
  Button,
  Loader,
  Link,
  Input,
  StripeInput,
  Fields,
} from '@pet-notify/ui'
import { useForm } from 'react-hook-form'

import type { ReplaceIntellitagOrder } from 'UserApp/services/replace-intellitag-orders/types'
import PaymentMethodIcons from 'UserApp/components/PaymentMethodIcons'
import {
  useCheckoutReplaceIntellitagOrder,
  useReplaceIntellitagOrderPricing,
} from 'UserApp/hooks/api/replace-intellitag-order'
import style from '../style.module.scss'
import { fetchPricing } from 'UserApp/services/replace-intellitag-orders'

type FormData = {
  zip?: string
}

type Props = {
  onSuccess: () => void
  replaceIntellitagOrder: ReplaceIntellitagOrder
}

function Checkout({ onSuccess, replaceIntellitagOrder }: Props) {
  const [error, setError] = useState<string>()
  const [isProcessing, setIsProcessing] = useState(false)
  // const [pricingQuery, setPricingQuery] = useState(null)
  const elements = useElements()
  const stripe = useStripe()
  const id = replaceIntellitagOrder?.id
  const { register, handleSubmit } = useForm<FormData>()

  // const pricingQuery = useReplaceIntellitagOrderPricing(id)
  const pricingQuery = useQuery(['replace-intellitag-order-pricing', id], () =>
    fetchPricing(id),
  )
  const checkoutMutation = useCheckoutReplaceIntellitagOrder(id)

  // useEffect(() => {
  //   setPricingQuery(useReplaceIntellitagOrderPricing(id))
  // }, [id])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.reload();
  //   }, 1000); // 1000 milliseconds = 1 second

  //   return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  // }, []);

  const isPaymentRequired = pricingQuery?.data?.total > 0

  async function onSubmit(data: FormData) {
    if (isPaymentRequired) {
      setIsProcessing(true)

      const cardNumberElement = elements.getElement(CardNumberElement)

      const { token, error } = await stripe.createToken(cardNumberElement, {
        address_zip: data.zip,
      })

      if (error) {
        setError(error.message)
      } else {
        checkoutMutation.mutate(
          { token: token.id },
          {
            onSuccess: () => {
              localStorage.removeItem('replace-intellitag-order-id')
              onSuccess()
            },
          },
        )
        setIsProcessing(false)
      }
    } else {
      checkoutMutation.mutate(
        {},
        {
          onSuccess: () => {
            localStorage.removeItem('replace-intellitag-order-id')
            onSuccess()
          },
        },
      )
    }
  }

  console.log(`PRICING :: `,pricingQuery)

  if (pricingQuery?.isLoading) {
    return <Loader />
  }

  const pricing = pricingQuery?.data

  return (
    <>
      <Header mb='6'>Order details</Header>

      <div className={style.activeSubscription}>
        You have an active premium subscription
      </div>

      <div className={style.summary}>
        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>
            <p className={style.summaryTitle}>Intellitag</p>
            <p className={style.summaryDescription}>
              Size: {replaceIntellitagOrder.meta.size}
              <br />
              Name: {replaceIntellitagOrder.meta.nameOnTag}
              {replaceIntellitagOrder.meta.phone && (
                <>
                  <br />
                  Phone: {replaceIntellitagOrder.meta.phone}
                </>
              )}
            </p>
          </dt>
          <dd className={style.summaryPrice}>
            <Link
              as={RouterLink}
              to='/replace-intellitag'
              className={style.link}
            >
              Edit order
            </Link>
            {pricingQuery?.data?.subTotal
              ? numeral(
                pricingQuery?.data?.subTotal > 0 ? pricingQuery?.data?.subTotal / 100 : 0,
                ).format('$0.00')
              : 'Included in subscription'}
          </dd>
        </dl>

        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>Shipping</dt>
          <dd className={style.summaryPrice}>
            {numeral(pricingQuery?.data?.shipping).format('$0.00')}
          </dd>
        </dl>
        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>Tax</dt>
          <dd className={style.summaryPrice}>
            {numeral(pricingQuery?.data?.tax > 0 ? pricingQuery?.data?.tax / 100 : 0).format('$0.00')}
          </dd>
        </dl>
        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>Total</dt>
          <dd className={style.summaryPrice}>
            {numeral(pricingQuery?.data?.total > 0 ? pricingQuery?.data?.total / 100 : 0).format(
              '$0.00',
            )}
          </dd>
        </dl>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {isPaymentRequired && (
          <Fields>
            <FieldGroup>
              <Label>
                Credit card number
                <StripeInput as={CardNumberElement} />
              </Label>
            </FieldGroup>

            <FieldGroup className={style.sideBySideFieldGroup}>
              <Label>
                Expiration (mm/yy)
                <StripeInput as={CardExpiryElement} />
              </Label>
              <Label>
                Security code (CVV)
                <StripeInput as={CardCvcElement} />
              </Label>
            </FieldGroup>

            <FieldGroup>
              <Label htmlFor='zip'>ZIP code</Label>
              <Input
                className={style.zipInput}
                id='zip'
                {...register('zip', { required: true })}
              />
            </FieldGroup>

            <PaymentMethodIcons />
          </Fields>
        )}

        {error && <Text variant='error'>{error}</Text>}

        {checkoutMutation.isError && (
          <>
          <Text variant='error'>{checkoutMutation.error}</Text>
          <div className={style.errorSpacing}></div>
          </>
        )}

        {isProcessing || checkoutMutation.isLoading ? (
          <Loader />
        ) : (
          <Button type='submit'>Confirm order</Button>
        )}
      </form>
    </>
  )
}

export default Checkout
