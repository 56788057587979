import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import api from 'services/pet-notify-api'
import Layout from 'components/Layout/SplitLayout'
import {
  Header,
  Text,
  Label,
  Input,
  Button,
  FieldGroup,
  Loader,
  Fields,
} from '@pet-notify/ui'
import useNotification from 'UserApp/hooks/useNotification'
import HeaderThree from 'components/Headers/HeaderThree'
import { Button as Btn } from '@pet-notify/ui'
import style from './style.module.scss'

function ForgotEmailScreen() {
  const { setNotification } = useNotification()
  const { register, handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [showList, setShowList] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [error, setError] = useState<string>()

  const onSubmit = handleSubmit(async ({ phone }) => {
    // console.log(`PHONE :: `,phone)
    setError(undefined)
    setIsLoading(true)
    setShowList(false)

    let sanitizedPhone = phone.replace(/[^0-9]/g,'')
    if(sanitizedPhone.length>10){
      sanitizedPhone = sanitizedPhone.slice(1,11)
    }
    else if(sanitizedPhone.length<10){
      setError('Please enter a valid phone')
    }

    try {
      const userAccounts = await api.users.forgotEmail({ phone: sanitizedPhone })
      // console.log('USER ACCOUNTS :: ',accounts)
      if(userAccounts.length > 0){
        setNotification('User accounts fetched.')
        setShowList(true)
        setAccounts(userAccounts)
      }else{
        setNotification('User not found')
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  })
  
  // console.log('USER ACCOUNTS :: ',accounts)

  const handleCopy = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      setNotification(`Copied ${email} to clipboard.`);
    } catch (error) {
      setNotification('Failed to copy email.');
    }
  };

  return (
    <Layout alternateBackground>
      <Header as='h1' size='xxlarge' mb='8'>
        Forgot Email
      </Header>

      <form onSubmit={onSubmit} className='mb-8'>
        <Fields>
          <FieldGroup>
            <Label htmlFor='forgot-email-phone'>Phone</Label>
            <Input
              type='tel'
              id='forgot-email-phone'
              {...register('phone')}
            />
          </FieldGroup>
          <Text>
            We will show Pet Notify accounts attached to this phone number below.
          </Text>
          {error && <Text variant='error'>{error}</Text>}
        </Fields>

        {isLoading ? <Loader /> : <Button type='submit'>Get my account</Button>}

        {
          showList && (accounts?.length > 0) && (
          <div className={style.userAccounts}>
              <HeaderThree className={style.header}>Pet Notify accounts</HeaderThree>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                {
                  accounts.map((account) => (
                      <tr key={account.id}>
                        <td style={{  borderBottom: '1px solid #ddd', padding: '8px' , marginRight: '20px'}}>{account.email}</td>
                        <td style={{  borderBottom: '1px solid #ddd', padding: '8px' , display: 'flex', alignItems: 'center'}}>
                          <Btn type="button" style={ {marginLeft: '50px', marginBottom:'5px'}} onClick={() => handleCopy(account.email)}>
                            Copy email
                          </Btn>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )
        }

      </form>
    </Layout>
  )
}
export default ForgotEmailScreen
