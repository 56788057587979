import React,{useState, useEffect} from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import numeral from 'numeral'

import INTELLITAG_SIZE_OPTIONS from 'lib/intellitag-size-options'
import Loader from 'components/Loader'
import {
  Form,
  Label,
  StripeInput,
  FieldGroup,
  Button,
  ErrorText,
  Checkbox,
} from 'components/Forms'
import Input from 'sharedComponents/Input'
import Link from 'sharedComponents/Link'
import LinkOne from 'components/Link'
import HeaderOne from 'components/Headers/HeaderOne'
import HeaderThree from 'components/Headers/HeaderThree'
import HeaderThreeOne from 'sharedComponents/HeaderThree'
import Text from 'components/Text'
import TextOne from 'sharedComponents/Text'
import RadioButton from 'sharedComponents/RadioButton'
import PaymentMethodIcons from 'UserApp/components/PaymentMethodIcons'
import AddressFormFields from '../AddressFormFields'
import PromoCode from '../../../components/InlinePromoCode'
import mixPanel, { EVENTS } from 'services/mix-panel'
import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'

function Payment(props) {

  const [userData, setUserData] = useState({})

  const {
    canMakePayment,
    isPaymentRequired,
    paymentRequest,
    breakdown,
    onSubmit,
    isProcessing,
    isLoading,
    wasSuccessful,
    shippingAddress,
    isMobile,
    subscriptionPlan,
    onChangeSubscriptionPlan,
  } = props
  // console.log(`INTELLITAG ORDER BREAKDOWN :: `,breakdown)

  const {user} = useUser()

  // const {subscriptionPlans} = breakdown

  useEffect(() => {
    setUserData(user)
  }, [])

  function handleChangeSubscriptionPlan(element) {
    onChangeSubscriptionPlan(element.value)
  }

  // console.log(`PAYEMENT REQUEST OBJECT :: `,paymentRequest)
  // console.log(`IS PAYMENT REQUIRED PROP :: `, isPaymentRequired)
  
  //   // Track if the paymentRequest is ready.
  // const [isPaymentRequestReady, setIsPaymentRequestReady] = useState(false);

    // Use effect to watch the paymentRequest object.
    // useEffect(() => {
    //   if (paymentRequest) {
    //     paymentRequest.canMakePayment().then((result) => {
    //       console.log('canMakePayment result:', result);
    //       setIsPaymentRequestReady(!!result);
    //     }).catch((error) => {
    //       console.error('Error with canMakePayment:', error);
    //       setIsPaymentRequestReady(false);
    //     });
    //   }
    // }, [paymentRequest]);

  if (wasSuccessful) {
    return (
      <Redirect
        to={
          isMobile
            ? '/order-intellitag/success?mobile=true'
            : '/order-intellitag/success'
        }
        push
      />
    )
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={style.wrapper}>
      <HeaderOne className={style.header}>Order details</HeaderOne>
      {(userData?.hasSubscription || userData?.status === 'premium') && ( 
        <div className={style.activeSubscription}>
        You have an active premium subscription
        </div>
      )}
      {(!userData?.hasSubscription || userData?.status === 'free') && ( 
        <div className={style.subscriptionSection}>
          <HeaderThreeOne className={style.subscriptionTitle}>
            Choose your premium plan
          </HeaderThreeOne>
          <TextOne className={style.subscriptionText}>
          One premium account monitors unlimited household pets Intellitags.
          No commitments, cancel anytime.
          </TextOne>

          {/* <LinkOne
            onClick={() => setIsShowingModal(true)}
            className={style.subscriptionLink}
          >
            What else does Premium include?
          </LinkOne> */}
          <div className={style.plans}>
          <RadioButton
            id='subscriptionPlan-year'
            name='subscriptionPlan'
            value='year'
            checked={subscriptionPlan === 'year'}
            onChange={handleChangeSubscriptionPlan}
            variant='small'
            className={style.radioButton}            
          >
            <strong>
              {numeral(breakdown.subscriptionPlans.year.amount / 12).format(
                '$0.00',
              )}
              /mo
            </strong>{' '}
            <div>
              paid annually (save{' '}
              {breakdown.subscriptionPlans.year.annualDiscountPercentOff ||
              45}
              %)
            </div>
            {/* Annual plan
            <strong>
              {numeral(breakdown.subscriptionPlans.year.amount / 12).format('$0.00')}/mo
            </strong>{' '}
            (save {breakdown.subscriptionPlans.year.annualDiscountPercentOff || 45}%) */}
          </RadioButton>
          <RadioButton
            id='subscriptionPlan-month'
            name='subscriptionPlan'
            value='month'
            checked={subscriptionPlan === 'month'}
            onChange={handleChangeSubscriptionPlan}
            variant='small'
            className={style.radioButton}
          >
                  <strong>
                    {numeral(breakdown.subscriptionPlans.month.amount).format(
                      '$0.00',
                    )}
                    /mo
                  </strong>{' '}
                  <div>paid monthly</div>
            {/* Monthly plan{'  '}
            <strong>
              {numeral(breakdown.subscriptionPlans.month.amount).format('$0.00')}/mo
            </strong> */}
          </RadioButton>

          </div>
        </div>
      )}
      {breakdown && (
        <div className={style.orderSection}>
          {breakdown.intellitags.map((intellitag) => (
            <dl className={style.orderLineItem} key={intellitag.nameOnTag}>
              <dt className={style.orderItem}>
                <p className={style.orderItemTitle}>Intellitag</p>
                <p className={style.orderItemDescription}>
                  Size:{' '}
                  {
                    INTELLITAG_SIZE_OPTIONS.find(
                      (option) => option.value === intellitag.size,
                    ).label
                  }
                  <br />
                  Name: {intellitag.nameOnTag}
                  {intellitag.phone && (
                    <React.Fragment>
                      <br />
                      Phone: {intellitag.phone}
                    </React.Fragment>
                  )}
                </p>
              </dt>
              <dd className={style.orderItemPrice}>
                <Link to='/order-intellitag' className={style.link}>
                  Edit order
                </Link>
                {numeral(breakdown.intellitagCost/100).format('$0.00')}
              </dd>
            </dl>
          ))}

          {/* <dl className={style.orderLineItem}>
              <dt className={style.orderItem}>Intellitag Activation and Monitoring</dt>
              <dd className={style.orderItemPrice}>
                {numeral(0).format('$0.00')}
              </dd>
          </dl> */}
          {breakdown.items.map((item) => (
            <dl className={style.orderLineItem} key={item.label}>
              <dt className={style.orderItem}>{item.label}</dt>
              <dd className={style.orderItemPrice}>
                {numeral(item.cost).format('$0.00')}
              </dd>
            </dl>
          ))}
        </div>
      )}

      {isPaymentRequired && <PromoCode />}

      {isPaymentRequired && paymentRequest && canMakePayment && (
        <div className={style.paymentButtons}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}

      <Form onSubmit={onSubmit}>
        {({ onChange, values, error }) => (
          <React.Fragment>
            {isPaymentRequired && (
              <>
                <Label>
                  Credit card number
                  <StripeInput
                    component={CardNumberElement}
                    onChange={onChange}
                  />
                </Label>

                <FieldGroup>
                  <div>
                    <Label>
                      Expiration (mm/yy)
                      <StripeInput
                        component={CardExpiryElement}
                        onChange={onChange}
                      />
                    </Label>
                  </div>
                  <div>
                    <Label>
                      Security code (CVV)
                      <StripeInput
                        component={CardCvcElement}
                        onChange={onChange}
                      />
                    </Label>
                  </div>
                </FieldGroup>

                <div className={style.zipCodeField}>
                  <Label htmlFor='zip'>Billing zip code</Label>
                  <Input
                    name='zip'
                    value={values.zip}
                    onChange={onChange}
                    id='zip'
                  />
                </div>

                <PaymentMethodIcons />
              </>
            )}

            {error && <ErrorText>{error}</ErrorText>}

            {isProcessing ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                className={style.button}
                onClick={() => mixPanel.track(EVENTS.CONFIRM_PAYMENT_CLICK)}
              >
                Confirm order
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default Payment
