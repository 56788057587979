import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import {
  create,
  fetchOne,
  fetchPricing,
  update,
  checkout,
} from 'UserApp/services/replace-intellitag-orders'
import type {
  CheckoutFormData,
  ReplaceIntellitagOrder,
  ReplaceIntellitagOrderPricing,
  RequestData,
} from 'UserApp/services/replace-intellitag-orders/types'

export function useReplaceIntellitagOrder(
  id?: string,
  options: UseQueryOptions<ReplaceIntellitagOrder> = {},
) {
  return useQuery(
    ['replace-intellitag-order', id],
    () => {
      if (id) {
        return fetchOne(id)
      }
    },
    {
      enabled: !!id,
      ...options,
    },
  )
}

export function useCreateReplaceIntellitagOrder() {
  const queryClient = useQueryClient()

  return useMutation((data: RequestData) => create(data), {
    onSuccess: (data) => {
      queryClient.setQueryData(['replace-intellitag-order', data.id], data)
    },
  })
}

export function useUpdateReplaceIntellitagOrder(id: string) {
  const queryClient = useQueryClient()

  return useMutation((data: RequestData) => update(id, data), {
    onSuccess: (data) => {
      queryClient.setQueryData(['replace-intellitag-order', data.id], data)
    },
  })
}

export function useCheckoutReplaceIntellitagOrder(id: string) {
  const queryClient = useQueryClient()

  return useMutation(
    (data: CheckoutFormData) => {
      return checkout(id, data)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['replace-intellitag-order', data.id], data)
      },
    },
  )
}

// export function useReplaceIntellitagOrderPricing(id: string) {
//   return useQuery(['replace-intellitag-order-pricing', id], () =>
//     fetchPricing(id),
//     // enabled: !!id
//   )
// }
